var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SwContainer',_vm._b({},'SwContainer',{
        stretched: _vm.config.full_height,
        paddingY: _vm.config.block_padding_y,
        bgColor: _vm.config.type_background === 'color'
            ? _vm.config.background_color
            : _vm.config.background_image_color,
        bgImage: _vm.config.background_url_image,
        bgOpacity: _vm.config.type_background === 'color'
            ? _vm.config.background_opacity
            : _vm.config.background_image_opacity,
    },false),[(_vm.styleKey === _vm.VARIANTS.TEXT_AND_FORM)?_c('div',{class:( _obj = {}, _obj[_vm.$style['block']] = true, _obj[_vm.$style['block_reversed']] = _vm.config.content_location_for_with_form === 'with_form_right', _obj )},[_c('FeedbackForm',{class:_vm.$style['block__form'],attrs:{"pivot-id":_vm.pivotId,"title":_vm.config.form_heading_text,"text":_vm.config.form_text_text,"fields":_vm.config.form_fields || [],"button-text":_vm.config.form_button_label,"button-text-after-submit":_vm.config.form_button_message_after_submit}}),_vm._v(" "),_c('ContactsAndText',{class:_vm.$style['block__text'],attrs:{"title":_vm.config.heading_text,"content":_vm.config.contacts_dynamic_items,"social":_vm.config.social_network_items,"inverted":_vm.config.inverted_font_color}})],1):_c('ContactsAndText',{attrs:{"title":_vm.config.heading_text,"content":_vm.config.contacts_dynamic_items,"social":_vm.config.social_network_items,"centered":_vm.config.content_location_for_text_only === 'text_only_center',"inverted":_vm.config.inverted_font_color}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }