<template>
    <SwContainer
        v-bind="{
            stretched: config.full_height,
            paddingY: config.block_padding_y,
            bgColor: config.type_background === 'color'
                ? config.background_color
                : config.background_image_color,
            bgImage: config.background_url_image,
            bgOpacity: config.type_background === 'color'
                ? config.background_opacity
                : config.background_image_opacity,
        }"
    >
        <div
            v-if="styleKey === VARIANTS.TEXT_AND_FORM"
            :class="{
                [$style['block']]: true,
                [$style['block_reversed']]: config.content_location_for_with_form === 'with_form_right'
            }"
        >
            <FeedbackForm
                :pivot-id="pivotId"
                :title="config.form_heading_text"
                :text="config.form_text_text"
                :fields="config.form_fields || []"
                :button-text="config.form_button_label"
                :button-text-after-submit="config.form_button_message_after_submit"
                :class="$style['block__form']"
            />

            <ContactsAndText
                :title="config.heading_text"
                :content="config.contacts_dynamic_items"
                :social="config.social_network_items"
                :inverted="config.inverted_font_color"
                :class="$style['block__text']"
            />
        </div>

        <ContactsAndText
            v-else
            :title="config.heading_text"
            :content="config.contacts_dynamic_items"
            :social="config.social_network_items"
            :centered="config.content_location_for_text_only === 'text_only_center'"
            :inverted="config.inverted_font_color"
        />
    </SwContainer>
</template>

<script>
const VARIANTS = {
  TEXT_ONLY: 'text_only',
  TEXT_AND_FORM: 'with_a_form'
}

export default {
  name: 'BlockContacts',

  components: {
    ContactsAndText: () => import('./BlockContacts/ContactsAndText'),
    FeedbackForm: () => import('@@/website/components/common/FeedbackForm')
  },

  props: {
    pivotId: {
      type: [Number, String],
      default: null
    },

    styleKey: {
      type: String,
      default: ''
    },

    config: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    VARIANTS: () => VARIANTS
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.block {
  display: flex;
  align-items: center;
  margin: auto 0;

  &__form {
    margin-right: auto;
  }

  &__text {
    width: 100%;
    max-width: 516px;
    margin-left: 75px;
  }

  &_reversed &__form {
    order: 1;
    margin-right: 0;
    margin-left: auto;
  }

  &_reversed &__text {
    margin-left: 0;
    margin-right: 75px;
    @media screen and (max-width: @bp-mobile-small) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: @bp-mobile-small) {
    flex-direction: column;
    &__form {
      margin: 0 auto 32px;
      max-width: 100%;
    }
    &__text {
      max-width: 100%;
      margin-left: 0;
    }
    &_reversed &__form {
      margin: 32px auto 0;
    }
  }
}
</style>
